import * as React from 'react';
import { PageProps } from 'gatsby';

import Seo from '../components/seo';
import Layout from '../components/layout';

import { blog } from '../paths';
import PostList from '../container/post-list';

const BlogPage: React.FC<PageProps> = ({ location }) => {
  return (
    <Layout location={location} page={blog}>
      <Seo title="Blog" />
      <PostList />
    </Layout>
  );
};

export default BlogPage;
